import * as React from "react";
import Layout from "../components/layout";

import { StaticImage } from "gatsby-plugin-image";
import { loadStripe } from "@stripe/stripe-js";

import Helmet from "react-helmet";

const formatNumber = new Intl.NumberFormat("en-CA", {
  style: "currency",
  currency: "CAD",
}).format;

const products = {
  election_1968: {
    name: "1968",
    price: 49.99,
    image: "../cartograms/canada/canada_1968.png",
    quantity: 0,
  },
  election_1988: {
    name: "1988",
    price: 49.99,
    image: "../cartograms/canada/canada_1988.png",
    quantity: 0,
  },
  election_1993: {
    name: "1993",
    price: 49.99,
    image: "../cartograms/canada/canada_1993.png",
    quantity: 0,
  },
  election_2011: {
    name: "2011",
    price: 49.99,
    image: "../cartograms/canada/canada_2011.png",
    quantity: 0,
  },
  election_2015: {
    name: "2015",
    price: 49.99,
    image: "../cartograms/canada/canada_2015.png",
    quantity: 0,
  },
  election_2019: {
    name: "2019",
    price: 49.99,
    image: "../cartograms/canada/canada_2019.png",
    quantity: 0,
  },
  election_2021: {
    name: "2021",
    price: 49.99,
    image: "../cartograms/canada/canada_2021.png",
    quantity: 0,
  },
};

const tax_rate = {
  SK: {
    name: "PST",
    rate: 0.06,
  },
};

function ProductDisplay(props) {
  return (
    <table>
      <thead>
        <tr>
          <th scope="col">Election</th>
          <th scope="col">Price</th>
          <th scope="col">Quantity</th>
          <th scope="col">Subtotal</th>
        </tr>
      </thead>

      {Object.entries(props.products).map(([id, product]) => {
        return (
          <tr>
            <td>
              <strong>{product.name}</strong>
            </td>
            <td className={product.reduced && "reduced"}>
              {formatNumber(product.price)}
            </td>
            <td>
              <form action="/action_page.php">
                <select
                  className="quantity"
                  type="number"
                  id={id}
                  name={id}
                  value={product.quantity}
                  onChange={props.onChange}
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                </select>
              </form>
            </td>
            <td>
              <span className="subtotal">
                {formatNumber(product.quantity * product.price)}
              </span>
            </td>
          </tr>
        );
      })}
    </table>
  );
}

export default class Index extends React.Component {
  constructor() {
    super();
    this.state = {
      products: products,
      country: undefined,
      province: "",
      errorMessage: undefined,
      shouldCheckout: false,
    };
    this.handleQuantityChange = this.handleQuantityChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleCheckout = this.handleCheckout.bind(this);
    this.handleProvinceChange = this.handleProvinceChange.bind(this);
    this.updateShouldCheckout = this.updateShouldCheckout.bind(this);
  }

  componentDidMount() {
    loadStripe(process.env.GATSBY_STRIPE_KEY).then((stripe) => {
      this.stripe = stripe;
      console.log(stripe);
    });
  }

  handleQuantityChange(event) {
    this.setState((state) => {
      const changedProduct = state.products[event.target.id];
      const newQuantity = event.target.value;
      const newEntry = { ...changedProduct, quantity: newQuantity };

      return {
        products: { ...state.products, [event.target.id]: newEntry },
      };
    }, this.updateShouldCheckout);
  }

  handleCountryChange(event) {
    this.setState(
      {
        country: event.target.id,
      },
      this.updateShouldCheckout
    );
  }

  handleProvinceChange(event) {
    this.setState(
      {
        province: event.target.value,
      },
      this.updateShouldCheckout
    );
  }

  updateShouldCheckout() {
    const countryOK = () => {
      return (
        (this.state.country === "canada" && this.state.province !== "") ||
        this.state.country === "us"
      );
    };

    const hasQuantity = (product) => {
      return product.quantity > 0;
    };

    const shouldCheckout =
      countryOK() && Object.values(this.state.products).some(hasQuantity);

    const errorMessage = shouldCheckout
      ? undefined
      : "Select a product and shipping location to continue.";

    this.setState({
      shouldCheckout,
      errorMessage,
    });

    return shouldCheckout;
  }

  handleCheckout() {
    if (this.state.shouldCheckout) {
      fetch(
        `${process.env.GATSBY_THIS_URL}/.netlify/functions/create-checkout-session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({...this.state, shipping: "smallTube", backPage: "elections"}),
        }
      )
        .then((response) => {
          if (response.ok || response.status === 500) {
            return response.json();
          } else {
            throw new Error(
              `Error: ${response.status} ${response.statusText}.`
            );
          }
        })
        .then((session) => {
          if (session.errorMessage) {
            throw new Error(`Server error: ${session.errorMessage}.`);
          }
          return this.stripe.redirectToCheckout({ sessionId: session.id });
        })
        .then((result) => {
          if (result.error) {
            throw new Error("Stripe encountered a problem.");
          }
        })
        .catch((error) => {
          let errorMessage;
          if (error instanceof TypeError) {
            errorMessage = "There was a problem connecting to Stripe.";
          } else if (error.message.startsWith("stripe")) {
            errorMessage = "Stripe encountered a problem.";
          } else {
            errorMessage = error.message;
          }

          this.setState({
            errorMessage: `${errorMessage} Please contact me so I can resolve this!`,
          });
        });
    }
  }

  getSubtotal() {
    let subtotal = 0;
    for (const id in this.state.products) {
      const product = this.state.products[id];
      subtotal += product.quantity * product.price;
    }

    return subtotal;
  }

  calculateTotal() {
    let subtotal = this.getSubtotal();

    if (subtotal > 0) {
      subtotal += this.getTax(subtotal);
      subtotal += 10;
    }

    return subtotal;
  }

  getTax(subtotal) {
    if (subtotal === undefined) {
      subtotal = this.getSubtotal();
    }

    if (this.state.country === "canada") {
      return subtotal * (tax_rate[this.state.province]?.rate || 0);
    } else {
      return 0;
    }
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Election Map Store</title>
          <meta name="description" content="Election Maps" />
          <meta name="image" content="https://awmcphee.ca/cartogram.png" />
          <meta
            property="og:image"
            content="https://awmcphee.ca/cartogram.png"
          />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@ksituan" />
          <meta name="twitter:title" content="Election Cartograms" />
          <meta
            name="twitter:description"
            content="Because land doesn't vote."
          />
          <meta
            name="twitter:image"
            content="https://awmcphee.ca/cartogram.png"
          />
        </Helmet>
        <h1>Election Map Store</h1>
        <h2>For the strategist in you</h2>
        <p>
          Election maps are a longtime obsession of mine. As a past
          federal candidate, I hope my work makes the subject a bit more
          colourful.
        </p>
        <h2>Federal cartograms</h2>
        <div className="awards">
          <a href="/canada_2021.png" target="_blank" rel="noopener noreferrer">
            <StaticImage
              src="../cartograms/canada/canada_2021.png"
              alt="A grid cartogram depicting the popular vote in the 2021 Canadian election."
              className="bigmap"
            />
          </a>
        </div>
        <div className="portfolio">
        <div className="pfs">
            <a
              href="/canada_1958.png"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StaticImage
                src="../cartograms/canada/canada_1958.png"
                alt="A grid cartogram depicting the popular vote in the 1958 Canadian election."
              />
            </a>
            <h4>1958 (Diefenbaker I)</h4>
          </div>
        <div className="pfs">
            <a
              href="/canada_1962.png"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StaticImage
                src="../cartograms/canada/canada_1962.png"
                alt="A grid cartogram depicting the popular vote in the 1988 Canadian election."
              />
            </a>
            <h4>1962 (Diefenbaker II)</h4>
          </div>
        <div className="pfs">
            <a
              href="/canada_1968.png"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StaticImage
                src="../cartograms/canada/canada_1968.png"
                alt="A grid cartogram depicting the popular vote in the 1968 Canadian election."
              />
            </a>
            <h4>1968 (P. Trudeau I)</h4>
          </div>
          <div className="pfs">
            <a
              href="/canada_1984.png"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StaticImage
                src="../cartograms/canada/canada_1984.png"
                alt="A grid cartogram depicting the popular vote in the 1984 Canadian election."
              />
            </a>
            <h4>1984 (Mulroney I)</h4>
          </div>
          <div className="pfs">
            <a
              href="/canada_1988.png"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StaticImage
                src="../cartograms/canada/canada_1988.png"
                alt="A grid cartogram depicting the popular vote in the 1988 Canadian election."
              />
            </a>
            <h4>1988 (Mulroney II)</h4>
          </div>
          <div className="pfs">
            <a
              href="/canada_1993.png"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StaticImage
                src="../cartograms/canada/canada_1993.png"
                alt="A grid cartogram depicting the popular vote in the 1993 Canadian election."
              />
            </a>
            <h4>1993 (Chrétien I)</h4>
          </div>
          <div className="pfs">
            <a
              href="/canada_2000.png"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StaticImage
                src="../cartograms/canada/canada_2000.png"
                alt="A grid cartogram depicting the popular vote in the 2000 Canadian election."
              />
            </a>
            <h4>2000 (Chrétien III)</h4>
          </div>
          <div className="pfs">
            <a
              href="/canada_2006.png"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StaticImage
                src="../cartograms/canada/canada_2006.png"
                alt="A grid cartogram depicting the popular vote in the 2006 Canadian election."
              />
            </a>
            <h4>2006 (Harper I)</h4>
          </div>
          <div className="pfs">
            <a
              href="/canada_2008.png"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StaticImage
                src="../cartograms/canada/canada_2008.png"
                alt="A grid cartogram depicting the popular vote in the 2008 Canadian election."
              />
            </a>
            <h4>2008 (Harper II)</h4>
          </div>
          <div className="pfs">
            <a
              href="/canada_2011.png"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StaticImage
                src="../cartograms/canada/canada_2011.png"
                alt="A grid cartogram depicting the popular vote in the 2011 Canadian election."
              />
            </a>
            <h4>2011 (Harper III)</h4>
          </div>
          <div className="pfs">
            <a
              href="/canada_2015.png"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StaticImage
                src="../cartograms/canada/canada_2015.png"
                alt="A grid cartogram depicting the popular vote in the 2015 Canadian election."
              />
            </a>
            <h4>2015 (J. Trudeau I)</h4>
          </div>
          <div className="pfs">
            <a
              href="/canada_2019.png"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StaticImage
                src="../cartograms/canada/canada_2019.png"
                alt="A grid cartogram depicting the popular vote in the 2019 Canadian election."
              />
            </a>
            <h4>2019 (J. Trudeau II)</h4>
          </div>
        </div>
        <p>
          Because land doesn't vote. These handsome posters show the exact
          popular vote in every riding. Check back frequently to see more
          historical elections added to the roster!
        </p>
        <p>Printed on satin photo paper at 18" x 36".</p>
        {/* <h2>Provincial legislatures</h2>
        <div className="awards">
          <a
            href="/canada_provinces.png"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StaticImage
              src="../cartograms/canada_provinces.png"
              alt="A grid cartogram depicting the popular vote in every Canadian provincial legislature."
              className="bigmap"
            />
          </a>
        </div>
        <p>This is a truly special map. Updated every provincial election, it shows the full popular vote in all 731 of Canada's partisan lower offices. Prints at a deluxe 72” x 24”.</p>
        <p>As this map changes a few times a year, I encourage especially well-heeled (or well-connected) fans of my work to inquire about my subscription service. Pay once a year and let me mail you new copies as needed, plus stickers for every by-election.</p> */}
        <h2>Checkout</h2>
        <p>Unfortunately, I can't consistently offer these for sale until I have my own plotter. The good news is, I'm saving up for one right now!</p>
        <p>Follow my Twitter for more notifications about when this product is available.</p>
{/*         <ProductDisplay
          products={this.state.products}
          onChange={this.handleQuantityChange}
        />
        <form className="country">
          <table>
            <tr>
              <td>
                {" "}
                <input
                  type="radio"
                  id="canada"
                  name="canada"
                  value="canada"
                  checked={this.state.country === "canada"}
                  onChange={this.handleCountryChange}
                />
                <label for="canada">
                  <div>
                    <span role="img" aria-label="A maple leaf">
                      🍁
                    </span>
                  </div>
                  <div>Canada</div>
                </label>
                {this.state.country === "canada" && (
                  <select
                    name="province"
                    onChange={this.handleProvinceChange}
                    value={this.state.province}
                  >
                    <option value="">--</option>
                    <option value="AB">AB</option>
                    <option value="BC">BC</option>
                    <option value="SK">SK</option>
                    <option value="MB">MB</option>
                    <option value="ON">ON</option>
                    <option value="QC">QC</option>
                    <option value="NB">NB</option>
                    <option value="PE">PE</option>
                    <option value="NS">NS</option>
                    <option value="NL">NL</option>
                    <option value="YT">YT</option>
                    <option value="NT">NT</option>
                    <option value="NU">NU</option>
                  </select>
                )}
              </td>
              <td>
                <input
                  type="radio"
                  id="us"
                  name="us"
                  value="us"
                  checked={this.state.country === "us"}
                  onChange={this.handleCountryChange}
                />
                <label for="us">
                  <div>
                    <span role="img" aria-label="A star">
                      ⭐
                    </span>
                  </div>
                  <div>United States</div>
                </label>
              </td>
              <td>
                {" "}
                <input
                  type="radio"
                  id="other"
                  name="other"
                  value="other"
                  checked={this.state.country === "other"}
                  onChange={this.handleCountryChange}
                />
                <label for="other">
                  <div>
                    <span role="img" aria-label="A globe">
                      🌍
                    </span>
                  </div>
                  <div>World</div>
                </label>
              </td>
            </tr>
          </table>
        </form>
        {this.state.country !== "other" && (
          <div className="total">
            {this.state.country === "canada" && tax_rate[this.state.province] && (
              <p className="total">
                {tax_rate[this.state.province].name}:{" "}
                {formatNumber(this.getTax())}
              </p>
            )}
            <p className="total">Shipping: {formatNumber(10)}</p>
            <p className="total">
              Total: <strong>{formatNumber(this.calculateTotal())}</strong>{" "}
              <small>{this.state.country === "canada" ? "CAD" : "USD"}</small>
            </p>
            <p className="total">
              <button
                type="button"
                id="checkout-button"
                className={this.state.shouldCheckout ? "" : "disabled"}
                onClick={this.handleCheckout}
              >
                Checkout
              </button>
            </p>
            {this.state.errorMessage && (
              <p className="error">{this.state.errorMessage}</p>
            )}
          </div>
        )}
        {this.state.country === "other" ? (
          <div>
            <p>Contact me directly for a quote.</p>
          </div>
        ) : (
          <div>
            <p>U.S. orders are charged in USD.</p>
          </div>
        )} */}
      </Layout>
    );
  }
}
